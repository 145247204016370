.draggable-section > * > .editable-item .btn-group svg {
  box-shadow: 0 0 5px 5px white;
  background: white;
}

.editable-item {
  min-height: 3rem;
  position: relative;

  .btn-group {
    display: none;
    position: absolute;
    right: 0;
    top: 0.3rem;
    .btn {
      padding-left: 0;
      padding-right: 0.2rem;
    }
  }

  &:hover .btn-group {
    display: flex;
  }
}

.article-deleted,
.section-deleted {
  opacity: 0.5;
  .article-name-truncate {
    color: red;
    &:after {
      content: ' (!)';
    }
  }
}

*[data-react-beautiful-dnd-droppable] {
  min-height: 15px;
}

.sort-warning {
  margin: 0.5rem;
}

.article-adder {
  height: 3em;
  margin: 0;
  padding-left: 1rem;
  button {
    margin: 0;
  }
}

.section-adder {
  height: 3em;
  margin: 0;
  margin-top: 1rem;
  padding-left: 1rem;
}

.article-name-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 2rem;
}

.draggable-section {
  border-bottom: 1px solid lightgrey;
}

.bottom-modal-noblock {
  .modal {
    top: unset;
    height: 0px;
    overflow: visible;
    .modal-dialog {
      max-width: 20rem;
    }
    .modal-content {
      margin-top: -170px;
    }
    .modal-body {
      padding: 0.5rem;
      text-align: center;
      .save-or-cancel {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.navable-article {
  transition: border 0.2s ease, font-weight 0.2s ease;
  position: relative;
  border-left: 4px solid transparent;

  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 0;
    background: var(--color-primary-dark);
    transition: all 0.2s;
  }

  a {
    color: grey;
  }

  &.article-active {
    border-left: 4px solid var(--color-primary-dark);
    font-weight: bold;

    a {
      color: var(--color-primary-dark);
      transition: color 0.2s ease;
    }

    &:before {
      opacity: 0.2;
    }
  }

  &:hover {
    background: white;
  }
}

.article-adder,
.section-adder {
  button {
    font-size: 0.85rem;
    margin-top: 0.2rem;
  }
}
