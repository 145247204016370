.hamburger-menu {
  width: 0;
  .main-menu {
    display: none;
    margin: 0;
    position: absolute;
    flex-direction: column;
    width: 350px;
    background-color: var(--color-primary);
    top: 0;
    z-index: 9999;
    overflow-y: auto;
    transition: right ease-in 0.2s;

    .right-container {
      flex-grow: 1;
    }
  }

  button.menu-toggle {
    width: 0;
    overflow: hidden;
    transition: width 0.2s;
    background: none;
    border: none;
    color: aliceblue;
    font-size: 1.5rem;
    padding: 0;
  }

  @keyframes add-opacity {
    to {
      color: var(--color-primary-light);
    }
  }
}

header.admin-mode .hamburger-menu .main-menu {
  height: calc(100vh - 2rem);
}

@media only screen and (max-width: 1200px) {
  .hamburger-menu {
    width: unset;
    overflow: hidden;
    button.menu-toggle {
      width: 3rem;
      display: flex;
      justify-content: center;
    }

    .main-menu {
      display: flex;
      padding-top: 0.3rem;
      right: -360px;
      overflow: hidden;

      &.visible {
        right: 0;
        box-shadow: -3px 1px 10px 0px rgb(0 0 0 / 38%), 0 3px 20px 0 rgb(0 0 0 / 19%);
      }

      .search-input {
        color: var(--grayscale-0);

        input {
          border: none;
          background-color: rgba(white, 0.15);
          color: var(--grayscale-0);
          transition: 0.2s ease background-color;
        }

        input::placeholder {
          color: var(--grayscale-0);
        }

        input:hover {
          background-color: rgba(white, 0.25);
        }

        .search-icon {
          position: absolute;
          top: 0.5rem;
          left: 0.85rem;
        }
      }

      .fast-search-result {
        border-bottom: 1px solid #cfcfcf;

        &:first-child {
          border-top: 1px solid #cfcfcf;
        }
        &:nth-child(even) {
          background-color: rgba(#f2f2f2d9, 0.85);
        }
      }

      .search-input-parent-stretcher {
        width: 100% !important;
      }

      .dropdown-menu {
        div:hover,
        div.active {
          background-color: var(--color-primary-light);
        }
      }

      .top {
        padding: 1rem;
        padding-right: 0;
        justify-content: flex-end;
        align-items: center;
        display: flex;
      }

      .middle {
        padding: 0.5rem;
        padding-top: 0.4rem;

        ol {
          li {
            float: none;

            a {
              font-size: 1rem !important;
              padding: 10px 15px;

              &.active {
                font-weight: bold;
                position: relative;

                .triangle-indicator {
                  fill: var(--color-primary-light);
                  position: absolute;
                  visibility: inherit;
                  top: 50%;
                  transform: translateY(-50%) rotate(90deg);
                  left: -5px;
                  margin: 0;
                }
              }
            }
          }
        }
      }

      .bottom {
        border-top: 2px solid rgba(black, 0.1);
        background-color: rgba(black, 0.1);
        padding: 0.5rem;
        display: grid;
        grid-template-columns: auto auto;
        gap: 0.5rem;
        margin-top: auto;

        button {
          width: 100%;
          height: auto;
          justify-self: end;
        }
      }
    }
  }
}

@media screen and(max-width: 576px) {
  .hamburger-menu {
    .header-top-row {
      > .right-container {
        display: block !important;
      }
    }
  }
}
